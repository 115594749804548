import { Button, Grid } from "@mui/material";
import { DrawerStatus, SideDrawer } from "../../kit/SideDrawer";
import { useState } from "react";
import moment from "moment";
import { CollectCustomerDTO, Customer, postCollectCustomer } from "../../../services/customers";
import { grayColor, primaryColor1, yellowColor3 } from "../../../utils/VARIABLES";
import icons from "../../../utils/icons";
import Typography from "../../kit/Typography";
import NumberInput from "../../kit/Inputs/Number";
import { numericFormatter } from "../../../utils/formatters";
import Checkbox from "../../kit/Selectors/Checkbox";

import "./CollectCustomerDrawer.scss";

const defaultCollectCustomerDTO = (customer: Customer): CollectCustomerDTO => {
  return {
    id: customer.id,
    paymentMethod: "Cash",
    changeReturned: true,
    amountPaid: null,
    collectedDate: moment().format("YYYY-MM-DD HH:mm")
  }
};

interface Props {
  customer: Customer,
  open: boolean,
  closeDrawer: () => void,
  updatePage: () => void
}

export function CollectCustomerDrawer(props: Props) {
  const [drawerStatus, setDrawerStatus] = useState<DrawerStatus>("idle");
  const [collectCustomerDTO, setCollectCustomerDTO] =
    useState<CollectCustomerDTO>(defaultCollectCustomerDTO(props.customer));

  const minAmountToPay = props.customer.totalAmount - 49;
  const disabled =
    drawerStatus === "loading" ||
    (!!collectCustomerDTO.amountPaid && collectCustomerDTO.amountPaid < minAmountToPay);

  const handleCollectCustomer = () => {
    setDrawerStatus("loading");
    (async () => {
      const response = await postCollectCustomer(collectCustomerDTO);
      if (response.status === 201) {
        setDrawerStatus("success")
      } else {
        setDrawerStatus("error")
      }
    })();
  }


  return (
    <Grid container>
      <SideDrawer
        disabled={disabled}
        drawerStatus={drawerStatus}
        title="Cobrar cliente"
        saveOnclick={handleCollectCustomer}
        saveLabel="Cobrar"
        closeDrawer={props.closeDrawer}
        open={props.open}
      >
        <Grid container className="collect-customer-drawer">
          <Grid className="box-payment-methods" container>
            <Grid className="payment-methods-cash" item xs={4} justifyContent="center">
              <Button
                style={{
                  borderColor:
                    collectCustomerDTO.paymentMethod === "Cash"
                      ? primaryColor1
                      : undefined,
                }}
                onClick={() => {
                  setCollectCustomerDTO((prevState: CollectCustomerDTO) => ({
                    ...prevState,
                    paymentMethod: "Cash",
                    amountPaid: null,
                  }))
                }}
                className="payment-method"
              >
                <Grid
                  className="payment-method-button"
                  onClick={() => {
                    setCollectCustomerDTO((prevState: CollectCustomerDTO) => ({
                      ...prevState,
                      paymentMethod: "Transfer",
                      amountPaid: null,
                    }))
                  }}
                  container
                >
                  <Grid
                    className={`payment-icon`}
                    container
                  >
                    {icons.simpleCash({
                      width: 28,
                      height: 28,
                      fill:
                        collectCustomerDTO.paymentMethod === "Cash"
                          ? primaryColor1
                          : undefined,
                    })}
                  </Grid>
                  <Grid className="payment-title" container>
                    <Typography
                      color={
                        collectCustomerDTO.paymentMethod === "Cash"
                          ? primaryColor1
                          : grayColor
                      }
                      variant="paragraph-10"
                    >
                      Efectivo
                    </Typography>
                  </Grid>
                </Grid>
              </Button>
            </Grid>
            <Grid className="payment-methods-transfer" item xs={4} justifyContent="center">
              <Button
                style={{
                  borderColor:
                    collectCustomerDTO.paymentMethod === "Transfer"
                      ? primaryColor1
                      : undefined,
                }}
                onClick={() => {
                  setCollectCustomerDTO((prevState: CollectCustomerDTO) => ({
                    ...prevState,
                    paymentMethod: "Transfer",
                    amountPaid: null,
                  }))
                }}
                className="payment-method"
              >
                <Grid className="payment-method-button" container>
                  <Grid className="payment-icon" container>
                    {icons.bank({
                      fill:
                        collectCustomerDTO.paymentMethod === "Transfer"
                          ? primaryColor1
                          : grayColor,
                    })}
                  </Grid>
                  <Grid className="payment-title" container>
                    <Typography
                      color={
                        collectCustomerDTO.paymentMethod === "Transfer"
                          ? primaryColor1
                          : grayColor
                      }
                      variant="paragraph-10"
                    >
                      Transfer.
                    </Typography>
                  </Grid>
                </Grid>
              </Button>
            </Grid>
            <Grid className="payment-methods-card" item xs={4} justifyContent="center">
              <Button
                style={{
                  borderColor:
                    collectCustomerDTO.paymentMethod === "DebitCard"
                      ? primaryColor1
                      : undefined,
                }}
                onClick={() => {
                  setCollectCustomerDTO((prevState: CollectCustomerDTO) => ({
                    ...prevState,
                    paymentMethod: "DebitCard",
                    amountPaid: null,
                  }))
                }}
                className="payment-method"
              >
                <Grid className="payment-method-button" container>
                  <Grid className="payment-icon" container>
                    {icons.card({
                      width: 24,
                      height: 24,
                      color:
                        collectCustomerDTO.paymentMethod === "DebitCard"
                          ? primaryColor1
                          : grayColor,
                    })}
                  </Grid>
                  <Grid className="payment-title" container>
                    <Typography
                      color={
                        collectCustomerDTO.paymentMethod === "DebitCard"
                          ? primaryColor1
                          : grayColor
                      }
                      variant="paragraph-10"
                    >
                      Débito
                    </Typography>
                  </Grid>
                </Grid>
              </Button>
            </Grid>
          </Grid>
          {collectCustomerDTO.paymentMethod === "Cash" && (
            <Grid className="collect-rent-drawer-cash" container>
              <Grid className="total-to-collect" container>
                <Grid container>
                  <Typography variant="paragraph-14">
                    Total a cobrar
                  </Typography>
                </Grid>
                <NumberInput
                  disabled
                  value={props.customer.totalAmount}
                  variant="line"
                  label=""
                  onChange={() => { }}
                />
              </Grid>
              <Grid className="amount-accredited-input" container>
                <NumberInput
                  value={collectCustomerDTO.amountPaid || ""}
                  variant="line"
                  onChange={(amountPaid: number) =>
                    setCollectCustomerDTO((prevState: CollectCustomerDTO) => ({
                      ...prevState,
                      amountPaid,
                    }))
                  }
                  label="Monto abonado"
                />
              </Grid>
              {(!!collectCustomerDTO.amountPaid && collectCustomerDTO.amountPaid < minAmountToPay) && (
                <Grid className="not-valid-amount-to-paid" container>
                  <Grid className="attention-box" container>
                    <Grid item xs={1} className="attention-box-icon" container>
                      {icons.circularWarning()}
                    </Grid>
                    <Grid item xs={11} className="attention-box-text" container>
                      <Typography
                        color={yellowColor3}
                        className="attention-box-title"
                        variant="paragraph-18"
                        type="bold"
                      >
                        Atención!
                      </Typography>
                      <Typography color={yellowColor3} variant="paragraph-14">
                        <>
                          El monto abonado no puede ser inferior a {numericFormatter(minAmountToPay)}
                        </>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {(
                !!collectCustomerDTO.amountPaid && collectCustomerDTO.amountPaid >= minAmountToPay &&
                collectCustomerDTO.amountPaid < props.customer.totalAmount
              ) && (
                  <Grid className="remaining-balance" container>
                    <Grid container>
                      <Typography variant="paragraph-14">
                        Deuda perdonada
                      </Typography>
                    </Grid>
                    <NumberInput
                      disabled
                      value={Math.abs(props.customer.totalAmount - (collectCustomerDTO.amountPaid))}
                      variant="line"
                      label=""
                      onChange={() => { }}
                    />
                  </Grid>
                )}
              {!(!collectCustomerDTO.amountPaid || collectCustomerDTO.amountPaid <= props.customer.totalAmount) && (
                <>
                  <Grid className="checkbox-mora" container alignItems="center">
                    <Grid item xs={1}>
                      <Checkbox
                        widthIcons={18}
                        checked={!!collectCustomerDTO.changeReturned}
                        onClick={() =>
                          setCollectCustomerDTO((prevState: CollectCustomerDTO) => ({
                            ...prevState,
                            changeReturned: !prevState.changeReturned,
                          }))
                        }
                      />
                    </Grid>
                    <Grid item xs={11}>
                      <Typography variant="paragraph-16">
                        Proporcionar el vuelto
                      </Typography>
                    </Grid>
                  </Grid>
                  {!!collectCustomerDTO.changeReturned && (
                    <Grid className="returned-amount" container>
                      <Grid container>
                        <Typography variant="paragraph-14">
                          Vuelto
                        </Typography>
                      </Grid>
                      <NumberInput
                        disabled
                        value={Math.abs(props.customer.totalAmount - (collectCustomerDTO.amountPaid))}
                        variant="line"
                        label=""
                        onChange={() => { }}
                      />
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          )}
          {(
            collectCustomerDTO.paymentMethod === "Transfer"
          ) && (
              <Grid className="amount-accredited" container>
                <Grid container>
                  <Typography variant="paragraph-14">
                    Monto abonado
                  </Typography>
                </Grid>
                <NumberInput
                  disabled
                  value={Math.abs(props.customer.totalAmount)}
                  variant="line"
                  label=""
                  onChange={() => { }}
                />
              </Grid>
            )
          }
          {(
            collectCustomerDTO.paymentMethod === "DebitCard"
          ) && (
              <Grid className="amount-accredited" container>
                <Grid container>
                  <Typography variant="paragraph-14">
                    Monto abonado
                  </Typography>
                </Grid>
                <NumberInput
                  disabled
                  value={Math.abs(props.customer.totalAmount)}
                  variant="line"
                  label=""
                  onChange={() => { }}
                />
              </Grid>
            )
          }
        </Grid>
      </SideDrawer>
    </Grid>
  )
}