import { Grid } from "@mui/material";
import { CustomerPage } from "../../components/Customer/CustomerPage";

export default function Customer() {

  return (
    <Grid container className="customer-page">
      <CustomerPage />
    </Grid>
  );
}
