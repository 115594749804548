import { Grid } from "@mui/material";
import { Checkout, closeCheckout, CloseCheckoutDto } from "../../../services/checkout";
import { useState } from "react";
import "./CloseCheckoutModal.scss";
import BasicModal from "../../kit/Modal";
import Typography from "../../kit/Typography";
import NumberInput from "../../kit/Inputs/Number";
import { greenColor2, redColor2 } from "../../../utils/VARIABLES";

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function CloseCheckoutModal(props: Props) {

  const [status, setStatus] = useState<"idle" | "loading" | "success" | "error">("idle");
  const [closeCheckoutDto, setCloseCheckoutDto] = useState<CloseCheckoutDto>({
    finalAmount: 0
  });

  const totalSteps = 2;
  const [step, setStep] = useState(1);

  const [closedCheckout, setClosedCheckout] = useState<Checkout | null>(null);

  const handleCloseCheckout = () => {
    setStatus("loading");
    (async () => {
      const res = await closeCheckout(closeCheckoutDto);
      if (res?.status !== 201) {
        setStatus("error");
        return;
      } else {
        setClosedCheckout(res.data);
        setStatus("success");
        setStep(2);
        // localStorage.removeItem("checkout-pin");
        // window.location.reload();
      }
    })();
  }

  const finishCloseCheckout = () => {
    localStorage.removeItem("checkout-pin");
    window.location.reload();
  }

  return (
    <BasicModal
      open={props.open}
      fullOpacity
      disabledClose={step !== 1}
      buttons={
        {
          save: {
            onClick: step === 1 ? handleCloseCheckout : finishCloseCheckout,
            title: step === 1 ? "Cerrar caja" : "Finalizar",
            color: "error",
          },
          cancel: {
            onClick: () => props.setOpen(false),
            title: "Cancelar",
            color: "secondary"
          }
        }
      }
      disabled={status === "loading"}
    >
      <Grid container className="close-checkout-modal">
        {step === 1 && (
          <>
            <Grid container className="confirmation-modal-title">
              <Typography variant="paragraph-18" type="bold">Cerrar caja</Typography>
            </Grid>
            <Grid container className="close-checkout-amount">
              <NumberInput
                label="Ingresá el monto en efectivo actual en caja"
                value={closeCheckoutDto.finalAmount}
                onChange={(value) => setCloseCheckoutDto({ finalAmount: value })}
              />
            </Grid>
          </>
        )}
        {step === 2 && (
          <Grid container className="view-closed-checkout">
            <Grid container className="view-closed-checkout-title">
              <Typography variant="paragraph-18" type="bold">Validación de cierre de caja</Typography>
            </Grid>
            {closedCheckout?.finalAmount === closeCheckoutDto.finalAmount && (
              <Grid container className="view-closed-checkout-amount">
                <NumberInput
                  disabled
                  value={0}
                  variant="line"
                  label=""
                  onChange={() => { }}
                />
                <Typography variant="paragraph-16">
                  ¡Perfecto! El balance es exacto. No hay diferencia.
                </Typography>
              </Grid>
            )}
            {(closedCheckout?.finalAmount || 0) < closeCheckoutDto.finalAmount && (
              <Grid container className="view-closed-checkout-amount">
                <NumberInput
                  disabled
                  value={Math.abs((closedCheckout?.finalAmount || 0) - closeCheckoutDto.finalAmount)}
                  variant="line"
                  label=""
                  onChange={() => { }}
                  color={greenColor2}
                />
                <Typography variant="paragraph-16">
                  <>
                    Tienes un excedente de {Math.abs((closedCheckout?.finalAmount || 0) - closeCheckoutDto.finalAmount)}. Revisa si ingresaste más dinero del esperado.
                  </>
                </Typography>
              </Grid>
            )}
            {(closedCheckout?.finalAmount || 0) > closeCheckoutDto.finalAmount && (
              <Grid container className="view-closed-checkout-amount">
                <NumberInput
                  disabled
                  value={Math.abs((closedCheckout?.finalAmount || 0) - closeCheckoutDto.finalAmount)}
                  variant="line"
                  label=""
                  onChange={() => { }}
                  color={redColor2}
                />
                <Typography variant="paragraph-16">
                  <>
                    Tienes un faltante de {Math.abs((closedCheckout?.finalAmount || 0) - closeCheckoutDto.finalAmount)}. Verifica si hubo algún error o faltante de dinero.
                  </>
                </Typography>
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
    </BasicModal>
  );
}

