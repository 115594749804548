import { Button, Grid } from "@mui/material";
import { DrawerStatus, SideDrawer } from "../../kit/SideDrawer";
import { useEffect, useRef, useState } from "react";
import Typography from "../../kit/Typography";
import { AutocompleteSearch } from "../../kit/Search";
import { getProductByCodeOrName, Product } from "../../../services/product";

import "./AssociateProductWithSupplierDrawer.scss";
import icons from "../../../utils/icons";
import ButtonLevel from "../../kit/Buttons";
import { addProductToSupplier } from "../../../services/supplier";

interface Props {
  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  openDrawer: boolean;
  updatePage: () => void;
  supplierId: number;
}

export default function AssociateProductWithSupplierDrawer(props: Props) {
  const [drawerStatus, setDrawerStatus] = useState<DrawerStatus>("idle");
  const [productToAdd, setProductToAdd] = useState<{ id: number, label: string }[]>([]);

  function addProduct(product: { id: number, label: string }) {
    setProductToAdd((prev) => {
      // Verificar si el producto ya está en la lista
      if (prev.some((p) => p.id === product.id)) {
        return prev; // Si ya existe, no lo agregamos
      }
      return [...prev, product]; // Agregar producto nuevo
    });
  }

  function removeProduct(product: { id: number, label: string }) {
    setProductToAdd((prev) => prev.filter((p) => p.id !== product.id));
  }


  const [search, setSearch] = useState<{
    value: string;
    loading: boolean;
    options: {
      id: number;
      label: string;
    }[];
  }>({
    value: "",
    options: [],
    loading: false,
  });

  const handleSearchProductOptions = () => {
    if (search.value === "") {
      setSearch({
        ...search,
        options: [],
        loading: false
      });
      return;
    }
    setSearch({
      ...search,
      loading: true
    });
    (async () => {
      const response = await getProductByCodeOrName(search.value);
      if (response.status === 200) {
        setSearch({
          ...search,
          options: response.data?.products?.map((product: Product) => ({ label: product.name, id: product.id })) || [],
          loading: false
        });
      }
    })();
  }

  const timeoutIdRef = useRef<NodeJS.Timeout | number | null>(null);
  useEffect(() => {
    // clear the timeout and prevent the callback from being called
    if (timeoutIdRef.current !== null) clearTimeout(timeoutIdRef.current);

    timeoutIdRef.current = setTimeout(handleSearchProductOptions, 500);
  }, [search.value]);

  const handleAddProductToSupplier = () => {
    (async () => {
      let response = await addProductToSupplier({
        supplierId: props.supplierId,
        productIds: productToAdd.map(product => product.id)
      });
      console.log(response)
      if (response.status === 201) {
        props.updatePage();
        setDrawerStatus("success");
      } else {
        setDrawerStatus("error");
      }
    })();
  };

  return (
    <Grid container>
      <SideDrawer
        title="Cargar producto"
        saveOnclick={handleAddProductToSupplier}
        saveLabel="Guardar"
        closeDrawer={() => props.setOpenDrawer(false)}
        open={props.openDrawer}
        drawerStatus={drawerStatus}
        onRetry={() => setDrawerStatus("idle")}
      >
        <Grid container className="associate-product-with-supplier-drawer">
          <Grid container className="input-drawer product-search">
            <Typography variant="paragraph-14">Agregar producto en la promoción</Typography>
            <AutocompleteSearch
              onChange={(value) => setSearch({
                ...search,
                value
              })}
              selectOption={(id: string | number) => {
                const productSelected = search.options.find(option => option.id === id);
                if (productSelected) {
                  addProduct(productSelected);
                }
              }}
              value={search.value}
              placeholder="Buscar producto"
              options={search.options}
              loading={search.loading}
              noOptionText="No se encontraron productos"
            />
          </Grid>
          <Grid container className="products-to-add-list">
            {productToAdd.map(product => (
              <Grid container className="product-to-add" alignItems="center">
                <Grid item xs={11}>
                  <Typography variant="paragraph-16">
                    {product.label}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <ButtonLevel
                    onClick={() => removeProduct(product)}
                    icon={{
                      icon: icons.close({ width: 14, height: 14, color: "red" }),
                      position: "end"
                    }}
                  />
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </SideDrawer>
    </Grid>
  )
}