import axios from "axios";
import { API_URL } from "../../config/env";
import { getToken } from "../utils/token";
import {
  DeleteResponse,
  GetResponse,
  PaginateOptions,
  PatchResponse,
  PostResponse,
  defaultPaginateOptions,
} from "../../utils/generalServiceResponses";
import { PaginatedResponse } from "../../utils/generalServiceResponses";
import { defaultPaginatedResponse } from "../../utils/generalServiceResponses";
import { Tag } from "../tags";
import { Promotion } from "../promotions";

export type ProductType = 'unit' | 'weight';

export type Product = {
  id: number;
  name: string;
  amount: number;
  stock: number;
  code: string;
  type: ProductType;
  purchasePrice: number; // precio de compra por unidad o kg
  dueDate: string;
  supplierId: number;
  wholesaleAmount: number;
  wholesaleMinQuantity: number;
  tag: Tag;
};

export async function getAllProduct(
  paginateOptions?: PaginateOptions
): Promise<PaginatedResponse<Product>> {
  if (paginateOptions === undefined)
    paginateOptions = { ...defaultPaginateOptions, limit: 100 };

  const token = await getToken();

  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.get(API_URL + "/product", {
      headers,
      params: {
        search: paginateOptions.search,
        page: paginateOptions.page,
        limit: paginateOptions.limit
      },
    });
    return response.data;
  } catch (e) {
    console.warn(e);
    return defaultPaginatedResponse;
  }
}

export type ProductDTO = {
  name?: string | null;
  amount?: number | null;
  stock: number | null;
  code?: string | null;
  type?: string | null;
  purchasePrice?: number | null; // precio de compra por unidad o kg
  dueDate?: string | null;
  supplierId?: number | null;
  tagId?: number | null;
  wholesaleMinQuantity?: number | null;
  wholesaleAmount?: number | null;
};

export async function postNewProduct(DTO: ProductDTO): Promise<PostResponse<Product>> {
  const token = await getToken();

  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.post(API_URL + "/product", DTO, {
      headers,
    });
    return response;
  } catch (error: any) {
    return error.response;
  }
}


export async function patchUpdateProductStock(dto: { id: number, stock: number }): Promise<PatchResponse> {
  const token = await getToken();

  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.patch(API_URL + "/product/set-new-stock", dto, {
      headers,
    });
    return response;
  } catch (error: any) {
    return error.response;
  }
}

export async function deleteProduct(id: number): Promise<DeleteResponse> {
  const token = await getToken();

  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.delete(API_URL + "/product/" + id, {
      headers,
    });
    return response;
  } catch (error: any) {
    return error.response;
  }
}
export type UpdateSupplierDTO = {
  id: number;
  name: string;
  phone: string;
  email: string;
};

export async function updateProduct(DTO: UpdateSupplierDTO) {
  const token = await getToken();
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.patch(API_URL + "/supplier", DTO, { headers });
    return response.data;
  } catch (e) {
    console.warn(e);
  }
}

type GetProductByCodeOrNameResponse = {
  count: number
  products: Product[],
  foundedPromotions: Promotion[]
}
export async function getProductByCodeOrName(
  code: string,
  getPromotions = false
): Promise<GetResponse<GetProductByCodeOrNameResponse>> {
  const token = await getToken();
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const response = await axios.get(API_URL + `/product/code-or-name?search=${code}&getPromotions=${getPromotions}`, { headers });
  return {
    data: response.data,
    status: response.status,
  };
}


export async function getAllProductBySupplierId(
  paginateOptions?: PaginateOptions,
  supplierId?: number
): Promise<PaginatedResponse<Product>> {
  if (paginateOptions === undefined)
    paginateOptions = { ...defaultPaginateOptions, limit: 100 };

  const token = await getToken();

  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.get(API_URL + "/supplier/products", {
      headers,
      params: {
        search: paginateOptions.search,
        page: paginateOptions.page,
        limit: paginateOptions.limit,
        supplierId,
      },
    });
    return response.data;
  } catch (e) {
    console.warn(e);
    return defaultPaginatedResponse;
  }
}

export async function getAllProductByTagId(
  paginateOptions?: PaginateOptions,
  tagId?: number
): Promise<PaginatedResponse<Product>> {
  if (paginateOptions === undefined)
    paginateOptions = { ...defaultPaginateOptions, limit: 100 };

  const token = await getToken();

  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.get(API_URL + "/product/tag/" + tagId, {
      headers,
      params: {
        search: paginateOptions.search,
        page: paginateOptions.page,
        limit: paginateOptions.limit
      },
    });
    return response.data;
  } catch (e) {
    console.warn(e);
    return defaultPaginatedResponse;
  }
}

export type EditProductAmountBulkDTO = {
  productIds: number[];
  percentageToEdit: number;
  updateType: 1 | -1;
}
export async function editProductAmountBulk(DTO: EditProductAmountBulkDTO): Promise<PostResponse<Product>> {
  const token = await getToken();

  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.post(API_URL + "/product/edit-products-amount", DTO, {
      headers,
    });
    return response;
  } catch (error: any) {
    return error.response;
  }
}