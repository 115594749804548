import { Grid } from "@mui/material"
import './TagTable.scss';
import { useEffect, useRef, useState } from "react";
import BasicTable, { BasicTableStatus } from "../../kit/BasicTable";
import { defaultPaginatedResponse, defaultPaginateOptions, PaginatedResponse } from "../../../utils/generalServiceResponses";
import { deleteProduct, getAllProductByTagId, Product } from "../../../services/product";
import { getTagById, Tag } from "../../../services/tags";
import Alert, { AlertType } from "../../kit/Alert";
import ConfirmationModal from "../../kit/Modal/ConfirmationModal";
import UpdateProductDrawer from "../../Product/UpdateProductDrawer";
import CreateNewProductDrawer from "../../Product/CreateNewProductDrawer";
import { numericFormatter } from "../../../utils/formatters";
import ButtonLevel from "../../kit/Buttons";
import UpdateProductStockDrawer from "../../Product/UpdateProductStockDrawer";

interface Props {
  tagId: number
}

export function TagTable(props: Props) {
  const [status, setStatus] = useState<BasicTableStatus>(
    "loading"
  );
  const [paginateOptions, setPaginateOptions] = useState(
    defaultPaginateOptions
  );
  const [productsByTagPaginated, setProductsByTagPaginated] = useState<
    PaginatedResponse<Product>
  >(defaultPaginatedResponse);
  const [tag, setTag] = useState<Tag | null>(null);

  // Table actions
  const [deleteProductModalConfirmation, setDeleteProductModalConfirmation] = useState<boolean>(false);
  const [productToUpdate, setProductToUpdate] = useState<Product | null>(
    null
  );
  const [productToEditStock, setProductToEditStock] = useState<Product | null>(
    null
  );
  const [productToDelete, setProductToDelete] = useState<Product | null>(
    null
  );

  const [alertStatusDelete, setAlertStatusDelete] =
    useState<AlertType>("close");

  const [openNewProductDrawer, setOpenNewProductDrawer] = useState(false);
  const [openUpdateProductDrawer, setOpenUpdateProductDrawer] = useState(false);
  const [openUpdateProductStockDrawer, setOpenUpdateProductStockDrawer] = useState(false);


  const handleGetProductsByTag = () => {
    setStatus("loading");
    (async () => {
      const response = await getAllProductByTagId(
        paginateOptions,
        props.tagId
      );
      if (response.data !== null) {
        setProductsByTagPaginated(response);
        setStatus("success");
      } else {
        setStatus("error");
      }
    })();
  };

  const handleDeleteProduct = () => {
    (async () => {
      if (!productToDelete) return;
      const response = await deleteProduct(productToDelete?.id);
      if (response.status === 200) {
        handleGetProductsByTag();
        setAlertStatusDelete("success");
      } else {
        setAlertStatusDelete("error");
      }

    })();
  }

  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    // clear the timeout and prevent the callback from being called
    if (timeoutIdRef.current !== null) clearTimeout(timeoutIdRef.current);

    timeoutIdRef.current = setTimeout(handleGetProductsByTag, 300);
  }, [paginateOptions.search, paginateOptions.page, paginateOptions.limit]);

  const handleGetTag = () => {
    (async () => {
      const response = await getTagById(
        props.tagId
      );
      if (response.status === 200) {
        setTag(response.data);
      }
    })();
  }
  useEffect(handleGetTag, []);


  return (
    <Grid container className="tag-table">
      <BasicTable
        status={status}
        head={tag?.name || ""}
        rightElement={
          <ButtonLevel
            fillWidth={false}
            title="Cargar producto"
            onClick={() => setOpenNewProductDrawer(true)}
          />
        }
        search={{
          value: paginateOptions.search,
          onChange: (value) => {
            if (value === "") setStatus("loading");
            setPaginateOptions({ ...paginateOptions, search: value });
          },
          placeholder: "Buscar por producto",
        }}
        options={[
          {
            label: "Editar Stock",
            onClick: (rowIndex: number) => {
              const supplierDetail = productsByTagPaginated.data[rowIndex];
              setProductToEditStock(supplierDetail);
              setOpenUpdateProductStockDrawer(true)
            },
          },
          {
            label: "Editar ",
            onClick: (rowIndex: number) => {
              const supplierDetail = productsByTagPaginated.data[rowIndex];
              setProductToUpdate(supplierDetail);
              setOpenUpdateProductDrawer(true)
            },
          },
          {
            label: "Eliminar ",
            onClick: (rowIndex: number) => {
              const product = productsByTagPaginated.data[rowIndex];
              setProductToDelete(product);
              setDeleteProductModalConfirmation(true);
            },
          },
        ]}
        columns={[
          "Nombre",
          "Stock",
          "Precio de compra",
          "Precio de venta",
        ]}
        rows={productsByTagPaginated.data.map((product) => {
          return [
            product.name,
            product.stock,
            numericFormatter(product.purchasePrice),
            numericFormatter(product.amount)
          ];
        })}
        paginateOptions={{
          currentPage: paginateOptions.page,
          rowsPerPage: paginateOptions.limit,
          setCurrentPage: (page: number) => {
            setPaginateOptions({ ...paginateOptions, page });
          },
          totalItems: productsByTagPaginated.totalItems,
        }}
      />
      <CreateNewProductDrawer
        tagId={props.tagId}
        updatePage={handleGetProductsByTag}
        openDrawer={openNewProductDrawer}
        setOpenDrawer={setOpenNewProductDrawer}
      />
      {productToUpdate !== null && (
        <UpdateProductDrawer
          product={productToUpdate}
          supplierId={props.tagId}
          updatePage={handleGetProductsByTag}
          openDrawer={openUpdateProductDrawer}
          closeDrawer={() => {
            setProductToUpdate(null);
          }}
        />
      )}
      {productToEditStock !== null && (
        <UpdateProductStockDrawer
          product={productToEditStock}
          supplierId={props.tagId}
          updatePage={handleGetProductsByTag}
          openDrawer={openUpdateProductStockDrawer}
          closeDrawer={() => {
            setProductToEditStock(null);
          }}
        />
      )}
      <ConfirmationModal
        title="Eliminar Producto"
        description={
          <>
            Este producto se eliminara permanentemente. <br />
            ¿Está seguro que desea continuar?
          </>
        }
        closeModal={() => setDeleteProductModalConfirmation(false)}
        open={deleteProductModalConfirmation ? true : false}
        confirmationOption={{
          onclick: () => {
            handleDeleteProduct();
            setDeleteProductModalConfirmation(false);
            setProductToDelete(null);
          },
          title: "Eliminar",
          color: "error",
        }}
      />
      {alertStatusDelete !== "close" && (
        <Alert
          setAlertStatus={setAlertStatusDelete}
          severity={alertStatusDelete}
          message={
            alertStatusDelete === "success"
              ? "El producto se elimino con exito"
              : "Ocurrío un error al eliminar el producto"
          }
        />
      )}
    </Grid>
  )
}
