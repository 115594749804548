import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Grid } from "@mui/material";
import DesktopMenu from "../components/BasicMenu/DesktopMenu";
import DashboardPage from "./dashboard";
import SessionPage from "./session";
import MovementPage from "./movement";
import AccountStatusPage from "./accountStatus";
import LandingPage from "./landing";
import ContactUsPage from "./contactUs";
import CheckoutPage from "./checkout";
import { User, UserContext, getMe } from "../services/user";
import OpenCheckoutModal from "../components/Checkout/OpenCheckoutModal";
import ModalTermsAndConditions from "../components/ModalTermsAndConditions";
import PartnershipsPage from "./partnerships";
import Supplier from "./suppliers";
import CartDesktop from "./cart";
import Tags from "./tags";
import PromotionPage from "./promotions";
import Customer from "./customer";
import CustomerTicket from "../components/Customer/CustomerTicket";

export const DesktopContext = React.createContext<boolean | null>(true);

export default function GestorKiosco() {
  const [open, setOpen] = useState(false);

  const [token, setToken] = useState<string | null>(
    localStorage.getItem("access_token") || null
  );

  const [user, setUser] = useState<User | null>(null);
  useEffect(() => {
    (async () => {
      if (token) {
        const user = await getMe();
        if (user !== null) setUser(user);
      }
    })();
  }, [token]);

  const [isDesktop, setIsDesktop] = useState<boolean | null>(null);
  useEffect(() => {
    const setIfIsDesktop = () => setIsDesktop(window.innerWidth >= 900);
    setIfIsDesktop();
    /* eslint-disable-next-line no-restricted-globals */
    addEventListener("resize", setIfIsDesktop);
    /* eslint-disable-next-line no-restricted-globals */
    return () => removeEventListener("resize", setIfIsDesktop);
  }, []);

  useEffect(() => {
    const expiration = localStorage.getItem("token_expiration");

    // validate expiration
    if (expiration) {
      const expirationDate = new Date(expiration);
      if (expirationDate < new Date()) {
        localStorage.removeItem("access_token");
        localStorage.removeItem("token_expiration");
        setToken("");
      }
    }

    const accessToken = localStorage.getItem("access_token");
    if (accessToken) setToken(accessToken);
  }, []);

  const renderMenu = (desktopPage: JSX.Element) => () => {
    if (isDesktop === null) return <></>;
    return (
      <DesktopMenu open={open} setOpen={setOpen}>
        <Grid className="desktop-menu" container>
          {desktopPage}
        </Grid>
      </DesktopMenu>
    );
  };

  var redirectIfHasNotPermissions = (page: () => React.ReactElement) => {
    if (
      user?.roles[0].role === "employee" &&
      ["/estado-de-cuenta", "/caja", "/proveedores"].includes(window.location.pathname)
    ) {
      window.location.replace('/inicio');
      return <></>; // TODO: we should redirect to unauthorized page
    }

    return page();
  };

  var showHomeFromAdminOrEmployee = () => {
    if (user?.roles[0].role === "employee") {
      return <CartDesktop />;
    }

    // TODO: mostrar panel de estadistica cuando lo desarrollemos
    // ademas agregar una nueva page para el carrito en caso de que el dueño en caso de querer pueda
    // return <DashboardPage />; 
    return <CartDesktop />;
  }

  useEffect(() => {
    if (window.location.pathname === "/") window.location.replace("/inicio");
  }, []);

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        highestRole: user?.roles[0].role || null,
      }}
    >
      {!token &&
        isDesktop !== null &&
        !["/", "/contacto", "/ticket"].includes(window.location.pathname) ? (
        <SessionPage setToken={setToken} />
      ) : (
        <DesktopContext.Provider value={isDesktop}>
          <Grid className="GestorKioscoApp">
            <OpenCheckoutModal />
            <ModalTermsAndConditions
              open={user !== null && !user.acceptTYC}
              closeModal={() => { }}
            />
            <Router>
              <Routes>
                <Route path="/" element={<></>} />
                <Route path="/contacto" element={<ContactUsPage />} />
                <Route
                  path="/inicio"
                  element={renderMenu(showHomeFromAdminOrEmployee())()}
                />
                <Route
                  path="/proveedores"
                  element={redirectIfHasNotPermissions(renderMenu(<Supplier />))}
                />
                <Route path="/productos" element={renderMenu(<Tags />)()} />
                <Route
                  path="/movimientos"
                  element={renderMenu(<MovementPage />)()}
                />
                <Route
                  path="/estado-de-cuenta"
                  element={redirectIfHasNotPermissions(
                    renderMenu(<AccountStatusPage />)
                  )}
                />
                <Route
                  path="/caja"
                  element={redirectIfHasNotPermissions(
                    renderMenu(<CheckoutPage />)
                  )}
                />
                <Route
                  path="/promociones"
                  element={redirectIfHasNotPermissions(
                    renderMenu(<PromotionPage />)
                  )}
                />
                <Route
                  path="/clientes"
                  element={redirectIfHasNotPermissions(
                    renderMenu(<Customer />)
                  )}
                />
                <Route
                  path="/ticket"
                  element={<CustomerTicket />}
                />
              </Routes>
            </Router>
          </Grid>
        </DesktopContext.Provider>
      )}
    </UserContext.Provider>
  );
}
