import { Grid } from "@mui/material";
import TextInput from "../../kit/Inputs/Text";
import "./CreateDrawerProduct.scss";
import { useEffect, useState } from "react";
import { DrawerStatus, SideDrawer } from "../../kit/SideDrawer";
import { postNewProduct, ProductDTO } from "../../../services/product";
import NumberInput from "../../kit/Inputs/Number";
import { getAllTag, Tag } from "../../../services/tags";
import SelectInput from "../../kit/Inputs/Select";
import { getAllSuppliers, Supplier } from "../../../services/supplier";
import Typography from "../../kit/Typography";

interface Props {
  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  openDrawer: boolean;
  updatePage: () => void;
  supplierId?: number;
  tagId?: number;
}

export default function CreateNewProductDrawer(props: Props) {
  const defaultProductDTO: ProductDTO = {
    name: null,
    amount: null,
    stock: null,
    code: null,
    type: "unit",
    purchasePrice: null, // precio de compra por unidad o kg
    dueDate: null,
    supplierId: !props.supplierId ? null : props.supplierId,
    tagId: !props.tagId ? null : props.tagId,
    wholesaleAmount: 0,
    wholesaleMinQuantity: null
  }

  const [productDTO, setProductDTO] = useState<ProductDTO>(defaultProductDTO);
  const [drawerStatus, setDrawerStatus] = useState<DrawerStatus>("idle");
  const [tags, setTags] = useState<Tag[]>([]);
  const [suppliers, setSuppliers] = useState<Supplier[]>([]);

  const handlePostProduct = () => {
    (async () => {
      let response = await postNewProduct(productDTO);
      if (response.status === 201) {
        props.updatePage();
        setDrawerStatus("success");
      } else {
        setDrawerStatus("error");
      }
    })();
  };

  const handleGetTags = () => {
    if (!props.supplierId) return null;
    (async () => {
      let response = await getAllTag();
      if (response.status === 200) {
        setTags(response.data?.tags || []);
      }
    })();
  };

  const handleGetSuppliers = () => {
    if (!props.tagId) return null;

    (async () => {
      let response = await getAllSuppliers();
      if (response.data) {
        setSuppliers(response.data);
      }
    })();
  };

  useEffect(() => {
    handleGetTags();
    handleGetSuppliers();
  }, []);

  const onCloseModal = () => {
    props.setOpenDrawer(false);
    setDrawerStatus("idle");
    setProductDTO(defaultProductDTO);
  };

  const disableSupplierDTO =
    !productDTO.name ||
    !productDTO.amount ||
    !productDTO.stock ||
    !productDTO.tagId ||
    (!!productDTO.wholesaleMinQuantity && !productDTO.wholesaleAmount);

  return (
    <Grid container>
      <SideDrawer
        title="Crear nuevo producto"
        disabled={disableSupplierDTO}
        saveOnclick={() => {
          handlePostProduct();
          setDrawerStatus("success");
        }}
        saveLabel="Guardar"
        closeDrawer={() => {
          onCloseModal();
          props.updatePage();
        }}
        open={props.openDrawer}
        drawerStatus={drawerStatus}
        onRetry={() => setDrawerStatus("idle")}
      >
        <Grid className="create-product-drawer" container>
          <Grid className="create-product-drawer-item" container>
            <SelectInput
              itemSelected={productDTO.type}
              onChange={(type) =>
                setProductDTO((prevState) => ({
                  ...prevState,
                  type,
                }))
              }
              label="Tipo"
              items={[{ value: 'unit', label: 'Unidad' }, { value: 'weight', label: 'Peso' }]}
            />
          </Grid>
          <Grid className="create-product-drawer-item" container>
            <TextInput
              isRequired
              value={productDTO.name || ""}
              onChange={(name) =>
                setProductDTO((prevState) => ({
                  ...prevState,
                  name,
                }))
              }
              label="Nombre"
            />
          </Grid>
          <Grid className="create-product-drawer-item" container>
            <TextInput
              value={productDTO.code || ""}
              onChange={(code) =>
                setProductDTO((prevState) => ({
                  ...prevState,
                  code,
                }))
              }
              label="Código"
            />
          </Grid>
          <Grid className="create-product-drawer-item" container>
            <NumberInput
              isRequired
              allowDecimals
              thousandSeparator
              value={productDTO.amount || ""}
              onChange={(amount) =>
                setProductDTO((prevState) => ({
                  ...prevState,
                  amount,
                }))
              }
              label={`Precio de venta ${productDTO.type === 'unit' ? 'por unidad' : 'por kg.'}`}
            />
          </Grid>
          <Grid className="create-product-drawer-item" container>
            <NumberInput
              isRequired
              value={productDTO.stock || ""}
              onChange={(stock) =>
                setProductDTO((prevState) => ({
                  ...prevState,
                  stock,
                }))
              }
              label={`Stock ${productDTO.type === 'unit' ? '(unidades)' : '(gramos)'}`}
            />
          </Grid>
          <Grid className="create-product-drawer-item" container>
            <NumberInput
              value={productDTO.purchasePrice || ""}
              onChange={(purchasePrice) =>
                setProductDTO((prevState) => ({
                  ...prevState,
                  purchasePrice,
                }))
              }
              label={<span>Precio de compra </span>}
            />
          </Grid>
          {props.supplierId && (
            <Grid className="create-product-drawer-item" container>
              <SelectInput
                itemSelected={productDTO.tagId}
                onChange={(tagId) =>
                  setProductDTO((prevState) => ({
                    ...prevState,
                    tagId,
                  }))
                }
                label="Etiqueta"
                items={tags.map((tag: Tag) => ({
                  value: tag.id,
                  label: tag.name,
                }))}
              />
            </Grid>
          )}
          {props.tagId && (
            <Grid className="create-product-drawer-item" container>
              <SelectInput
                itemSelected={productDTO.supplierId}
                onChange={(supplierId) =>
                  setProductDTO((prevState) => ({
                    ...prevState,
                    supplierId,
                  }))
                }
                label="Provedor"
                items={suppliers.map((tag: Supplier) => ({
                  value: tag.id,
                  label: tag.name,
                }))}
              />
            </Grid>
          )}
          <Grid className="create-product-drawer-item" container>
            <Typography variant="paragraph-16" type="semibold">Venta por mayor</Typography>
          </Grid>
          <Grid className="create-product-drawer-item" container>
            <NumberInput
              value={productDTO.wholesaleMinQuantity || ""}
              onChange={(wholesaleMinQuantity) =>
                setProductDTO((prevState) => ({
                  ...prevState,
                  wholesaleMinQuantity,
                }))
              }
              label={`Cantidad mínima ${productDTO.type === 'unit' ? '(unidades)' : '(gramos)'}`}
            />
          </Grid>
          <Grid className="create-product-drawer-item" container>
            <NumberInput
              isRequired={!!productDTO.wholesaleMinQuantity}
              allowDecimals
              thousandSeparator
              value={productDTO.wholesaleAmount || ""}
              onChange={(wholesaleAmount) =>
                setProductDTO((prevState) => ({
                  ...prevState,
                  wholesaleAmount,
                }))
              }
              label={`Precio unitario por mayor ${productDTO.type === 'unit' ? '(unidades)' : '(gramos)'}`}
            />
          </Grid>
        </Grid>
      </SideDrawer>
    </Grid>
  );
}
