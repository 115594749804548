import {
  Drawer,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React, { ReactNode, useContext, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./DesktopMenu.scss";
import ButtonLevel from "../../kit/Buttons";
import Tooltip from "../../kit/Tooltip";
import icons from "../../../utils/icons";
import { backgroundColor1, grayColor, primaryColor1, secondaryColor3 } from "../../../utils/VARIABLES";
import { User, UserContext, permissions } from "../../../services/user";
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import LiquorOutlinedIcon from '@mui/icons-material/LiquorOutlined';
import PointOfSaleOutlinedIcon from '@mui/icons-material/PointOfSaleOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import CloseCheckoutModal from "../../Checkout/CloseCheckoutModal";

interface Props {
  children?: ReactNode;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function DesktopMenu(props: Props) {
  const { user, highestRole } = useContext(UserContext);
  const [openCloseCheckoutModal, setOpenCloseCheckoutModal] = useState(false);

  const { children } = props;

  const location = useLocation();

  const handleCloseCheckout = () => {
    (async () => {
      if (highestRole === 'employee') {
        setOpenCloseCheckoutModal(true)
      } else {
        localStorage.removeItem("access_token");
        localStorage.removeItem("token_expiration");

        window.location.href = "/inicio";
      }
    })();
  }

  return (
    <Grid container className="desktop-menu">
      <Grid container>
        {collapsedMenu(props.setOpen, location.pathname, user)}
      </Grid>
      <Grid>
        {drawerMenu(props.open, props.setOpen, location.pathname, user, handleCloseCheckout)}
      </Grid>
      <Grid
        container
        className="desktop-menu-body"
      >
        {children}
      </Grid>
      <CloseCheckoutModal 
        open={openCloseCheckoutModal}
        setOpen={() => setOpenCloseCheckoutModal(false)}
      />
    </Grid>
  );
}

function collapsedMenu(
  setOpen: (value: boolean) => void,
  currentPath: string,
  user: User | null
) {
  return (
    <Grid container className="collapsed-menu" spacing={1}>
      <Grid container className="menu-logo">
        <img src="/images/LogoMarcos.png" alt="Logo" />
      </Grid>
      <Tooltip title="Inicio" placement="right">
        <Grid container className="menu-item">
          <ButtonLevel
            href="/inicio"
            icon={{
              icon: <AddShoppingCartIcon sx={{ color: currentPath === "/inicio" ? primaryColor1 : secondaryColor3, fontSize: 20, stroke: backgroundColor1 }} />
            }}
          />
        </Grid>
      </Tooltip>
      {permissions.canShowSuppliers(user) && (
        <Tooltip title="Proveedores" placement="right">
          <Grid container className="menu-item">
            <ButtonLevel
              href="/proveedores"
              icon={{
                icon: <AssignmentTurnedInOutlinedIcon sx={{ color: currentPath === "/proveedores" ? primaryColor1 : secondaryColor3, fontSize: 20, stroke: backgroundColor1 }} />
              }}
            />
          </Grid>
        </Tooltip>
      )}
      <Tooltip title="Productos" placement="right">
        <Grid container className="menu-item">
          <ButtonLevel
            href="/productos"
            icon={{
              icon: <LiquorOutlinedIcon sx={{ color: currentPath === "/productos" ? primaryColor1 : secondaryColor3, fontSize: 20, stroke: backgroundColor1 }} />
            }}
          />
        </Grid>
      </Tooltip>
      <Tooltip title="Promociones" placement="right">
        <Grid container className="menu-item">
          <ButtonLevel
            href="/promociones"
            icon={{
              icon: icons.promotionPercentage({ color: currentPath === "/promociones" ? primaryColor1 : undefined })
            }}
          />
        </Grid>
      </Tooltip>
      <Tooltip title="Clientes" placement="right">
        <Grid container className="menu-item">
          <ButtonLevel
            href="/clientes"
            icon={{
              icon: <PeopleAltOutlinedIcon sx={{ color: currentPath === "/clientes" ? primaryColor1 : secondaryColor3, fontSize: 20, stroke: backgroundColor1 }} />
            }}
          />
        </Grid>
      </Tooltip>
      <Tooltip title="Movimientos de cuenta" placement="right">
        <Grid container className="menu-item">
          <ButtonLevel
            href="/movimientos"
            icon={{
              icon: icons.movementAccount({
                color: currentPath === "/movimientos" ? primaryColor1 : undefined,
              })
            }}
          />
        </Grid>
      </Tooltip>
      {permissions.canShowAccountStatusPage(user) && (
        <Tooltip title="Estado de cuenta" placement="right">
          <Grid container className="menu-item">
            <ButtonLevel
              href="/estado-de-cuenta"
              icon={{
                icon: icons.statusAccount({
                  color:
                    currentPath === "/estado-de-cuenta"
                      ? primaryColor1
                      : undefined,
                })
              }}
            />
          </Grid>
        </Tooltip>
      )}
      {permissions.canShowAccountStatusPage(user) && (
        <Tooltip title="Caja empleados" placement="right">
          <Grid container className="menu-item box">
            <ButtonLevel
              href="/caja"
              icon={{
                icon: <PointOfSaleOutlinedIcon sx={{ color: currentPath === "/caja" ? primaryColor1 : secondaryColor3, fontSize: 20, stroke: backgroundColor1 }} />
              }}
            />
          </Grid>
        </Tooltip>
      )}
      <Grid container className="menu-item logout-menu-item">
        <ButtonLevel icon={{ icon: icons.deploy() }} onClick={() => setOpen(true)} />
      </Grid>
    </Grid>
  );
}

function drawerMenu(
  open: boolean,
  setOpen: (value: boolean) => void,
  currentPath: string,
  user: User | null,
  handleCloseCheckout: () => void
) {
  return (
    <Drawer onClose={() => setOpen(false)} open={open}>
      <List>
        <Grid className="desktop-menu-drawer" container>
          <Grid container className="logo-container">
            <img src="images/LogoMarcos.png" alt="logo" />
          </Grid>

          <Grid className="menu-item" container>
            <ListItem component={Link} to="/inicio">
              <ListItemIcon sx={{ minWidth: "45px" }} className="list-icon">
                <AddShoppingCartIcon sx={{ color: currentPath === "/inicio" ? primaryColor1 : secondaryColor3, fontSize: 34 }} />
              </ListItemIcon>
              <ListItemText
                primary="Inicio"
                sx={{
                  color: currentPath === "/inicio" ? primaryColor1 : grayColor,
                }}
              />
            </ListItem>
          </Grid>

          <Grid className="menu-item" container>
            <ListItem component={Link} to="/proveedores">
              <ListItemIcon sx={{ minWidth: "45px" }}>
                <AssignmentTurnedInOutlinedIcon sx={{ color: currentPath === "/proveedores" ? primaryColor1 : secondaryColor3, fontSize: 34 }} />
              </ListItemIcon>
              <ListItemText
                primary="Proveedores"
                sx={{
                  color:
                    currentPath === "/proveedores" ? primaryColor1 : grayColor,
                }}
              />
            </ListItem>
          </Grid>
          <Grid className="menu-item" container>
            <ListItem component={Link} to="/productos">
              <ListItemIcon sx={{ minWidth: "45px" }}>
                <LiquorOutlinedIcon sx={{ color: currentPath === "/productos" ? primaryColor1 : secondaryColor3, fontSize: 34 }} />
              </ListItemIcon>
              <ListItemText
                primary="Productos"
                sx={{
                  color: currentPath === "/productos" ? primaryColor1 : grayColor,
                }}
              />
            </ListItem>
          </Grid>
          <Grid className="menu-item" container>
            <ListItem component={Link} to="/promociones">
              <ListItemIcon sx={{ minWidth: "45px" }}>
                {icons.promotionPercentage({
                  width: 34,
                  height: 34,
                  color: currentPath === "/promociones" ? primaryColor1 : undefined,
                  stroke: 1
                })}
              </ListItemIcon>
              <ListItemText
                primary="Promociones"
                sx={{
                  color: currentPath === "/promociones" ? primaryColor1 : grayColor,
                }}
              />
            </ListItem>
          </Grid>
          <Grid className="menu-item" container>
            <ListItem component={Link} to="/clientes">
              <ListItemIcon sx={{ minWidth: "45px" }}>
                <PeopleAltOutlinedIcon sx={{ color: currentPath === "/clientes" ? primaryColor1 : secondaryColor3, fontSize: 34 }} />
              </ListItemIcon>
              <ListItemText
                primary="Clientes"
                sx={{
                  color: currentPath === "/clientes" ? primaryColor1 : grayColor,
                }}
              />
            </ListItem>
          </Grid>
          <Grid className="menu-item" container>
            <ListItem component={Link} to="/movimientos">
              <ListItemIcon sx={{ minWidth: "45px" }}>
                {icons.movementAccount({
                  width: 25,
                  height: 25,
                  color: currentPath === "/movimientos" ? primaryColor1 : undefined,
                  stroke: 2
                })}
              </ListItemIcon>
              <ListItemText
                primary="Movimiento de cuenta"
                sx={{
                  color:
                    currentPath === "/movimientos" ? primaryColor1 : grayColor,
                }}
              />
            </ListItem>
          </Grid>
          {permissions.canShowAccountStatusPage(user) && (
            <Grid className="menu-item" container>
              <ListItem component={Link} to="/estado-de-cuenta">
                <ListItemIcon sx={{ minWidth: "45px" }}>
                  {icons.statusAccount({
                    width: 25,
                    height: 25,
                    stroke: 1.7,
                    color:
                      currentPath === "/estado-de-cuenta"
                        ? primaryColor1
                        : undefined,
                  })}
                </ListItemIcon>
                <ListItemText
                  primary="Estado de cuenta"
                  sx={{
                    color:
                      currentPath === "/estado-de-cuenta"
                        ? primaryColor1
                        : grayColor,
                  }}
                />
              </ListItem>
            </Grid>
          )}
          {permissions.canShowAccountStatusPage(user) && (
            <Grid className="menu-item" container>
              <ListItem component={Link} to="/caja">
                <ListItemIcon sx={{ minWidth: "45px" }}>
                  <PointOfSaleOutlinedIcon sx={{ color: currentPath === "/caja" ? primaryColor1 : secondaryColor3, fontSize: 34 }} />
                </ListItemIcon>
                <ListItemText
                  primary="Caja"
                  sx={{
                    color: currentPath === "/caja" ? primaryColor1 : grayColor,
                  }}
                />
              </ListItem>
            </Grid>
          )}
          <Grid className="menu-item-sign-out a" container>
            <ListItem
              component={Link}
              to="/inicio"
              onClick={handleCloseCheckout}
            >
              <ListItemIcon sx={{ minWidth: "45px" }}>
                {icons.logout({
                  width: 40,
                  height: 40,
                  color: currentPath === "/exit" ? primaryColor1 : undefined,
                })}
              </ListItemIcon>
              <ListItemText
                primary={user?.roles[0].role === 'employee' ? 'Cerrar caja' : 'Cerrar sesión'}
                sx={{
                  color: currentPath === "/exit" ? primaryColor1 : grayColor,
                }}
              />
            </ListItem>
          </Grid>
        </Grid>
      </List>
    </Drawer>
  );
}
