import axios from "axios";
import { API_URL } from "../../config/env";
import { getToken } from "../utils/token";
import {
  DeleteResponse,
  GetResponse,
  PaginateOptions,
  PostResponse,
  defaultPaginateOptions,
} from "../../utils/generalServiceResponses";
import { PaginatedResponse } from "../../utils/generalServiceResponses";
import { defaultPaginatedResponse } from "../../utils/generalServiceResponses";


export type Promotion = {
  id: number;
  name: string;
  amount: number;
  endDate: string;
};

export async function getAllPromotions(
  paginateOptions?: PaginateOptions
): Promise<PaginatedResponse<Promotion>> {
  if (paginateOptions === undefined)
    paginateOptions = { ...defaultPaginateOptions, limit: 100 };

  const token = await getToken();

  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.get(API_URL + "/promotion", {
      headers,
      params: {
        search: paginateOptions.search,
        page: paginateOptions.page,
        limit: paginateOptions.limit
      },
    });
    return response.data;
  } catch (e) {
    console.warn(e);
    return defaultPaginatedResponse;
  }
}

export type PromotionProductDTO = {
  productId: number | null;
  quantity: number;
  name?: string; // name of the product, only is used in the frontend
}
export type PromotionDTO = {
  name: string;
  amount: number;
  products: PromotionProductDTO[];
  endDate?: string | null;
}

export async function createPromotion(dto: PromotionDTO): Promise<PostResponse<Promotion>> {
  const token = await getToken();

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  dto.products = dto.products.filter(product => product.productId && product.quantity);

  try {
    const response = await axios.post(API_URL + "/promotion", dto, {
      headers
    });

    return response;
  } catch (error: any) {
    return error.response;
  }
}
export async function deletePromotion(id: number) {
  const token = await getToken();
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.delete(API_URL + "/promotion/" + id, {
      headers,
    });
    return response.data;
  } catch (e) {
    console.warn(e);
  }
}