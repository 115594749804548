import axios from "axios";
import {
  defaultPaginatedResponse,
  defaultPaginateOptions,
  DeleteResponse,
  GetResponse,
  PaginatedResponse,
  PaginateOptions,
  PatchResponse,
  PostResponse,
} from "../../utils/generalServiceResponses";
import { getToken } from "../utils/token";
import { API_URL } from "../../config/env";
import { ProductType } from "../product";
import { PaymentMethod } from "../cart";

export type CustomerProduct = {
  id: number;
  quantity: number;
  productName: string;
  productStock: string;
  productType: ProductType;
  productPrice: number; // Monto que sale el producto
  productAmount: number; // Monto que debe pagar el cliente
};

export type Customer = {
  id: number;
  firstName: string;
  lastName: string;
  document: string;
  address: string | null;
  email: string | null;
  phone: string | null;
  totalAmount: number;
  products: CustomerProduct[];
};

export async function getAllCustomers(
  paginateOptions?: PaginateOptions
): Promise<PaginatedResponse<Customer>> {
  if (paginateOptions === undefined)
    paginateOptions = { ...defaultPaginateOptions, limit: 100 };

  const token = await getToken();

  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.get(API_URL + "/customer", {
      headers,
      params: {
        search: paginateOptions.search,
        page: paginateOptions.page,
        limit: paginateOptions.limit,
      },
    });
    return response.data;
  } catch (e) {
    console.warn(e);
    return defaultPaginatedResponse;
  }
}

export async function getCustomerById(
  id: number
): Promise<GetResponse<Customer>> {
  const token = await getToken();

  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.get(API_URL + "/customer/" + id, {
      headers,
    });
    return response;
  } catch (e: any) {
    console.warn(e);
    return e.response;
  }
}

export type CustomerDTO = {
  firstName: string;
  lastName: string;
  document: string;
  address: string;
  email: string;
  phone: string;
};

export async function postNewCustomer(
  dto: CustomerDTO
): Promise<PostResponse<Customer>> {
  const token = await getToken();

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  try {
    const response = await axios.post(API_URL + "/customer", dto, {
      headers,
    });

    return response;
  } catch (error: any) {
    return error.response;
  }
}

export async function deleteCustomer(id: number): Promise<DeleteResponse> {
  const token = await getToken();

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  try {
    const response = await axios.delete(API_URL + "/customer/" + id, {
      headers,
    });

    return response;
  } catch (error: any) {
    return error.response;
  }
}

export type CustomerUpdateDTO = {
  id: number;
  firstName?: string | null;
  lastName?: string | null;
  document?: string | null;
  address?: string | null;
  email?: string | null;
  phone?: string | null;
};

export async function updateCustomer(
  dto: CustomerUpdateDTO
): Promise<PatchResponse> {
  const token = await getToken();

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  try {
    const response = await axios.patch(API_URL + "/customer", dto, {
      headers,
    });

    return response;
  } catch (error: any) {
    return error.response;
  }
}

export type AddProductForCustomerDTO = {
  customerId: number;
  productId: number;
  quantity: number;
};
export type AddProductForCustomerBatchDTO = {
  batch: AddProductForCustomerDTO[];
};
export async function addProductForCustomerBatch(
  dto: AddProductForCustomerBatchDTO
): Promise<PostResponse<any>> {
  const token = await getToken();

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  try {
    const response = await axios.post(
      API_URL + "/customer/add-product-for-customer-batch",
      dto,
      {
        headers,
      }
    );

    return response;
  } catch (error: any) {
    return error.response;
  }
}

export type CollectCustomerDTO = {
  id: number;
  paymentMethod: PaymentMethod;
  changeReturned: boolean | null;
  amountPaid: number | null;
  collectedDate: string;
};
export async function postCollectCustomer(
  DTO: CollectCustomerDTO
): Promise<PostResponse<any>> {
  const token = await getToken();

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  try {
    const response = await axios.post(API_URL + "/customer/collect", DTO, {
      headers,
    });

    return response;
  } catch (error: any) {
    return error.response;
  }
}
